import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Nav from '../components/nav2/nav';
import Footer from '../components/Footer1/Footer';
import PrivacySection from '../components/PrivacySection/PrivacySection';

const PrivacyPage = ({ data, location }) => {
  const companyName = data.site.siteMetadata.companyName;
  return (
    <div>
      <Nav
        title="古材活用ドットコム"
        navItems={[
          {
            id: 1,
            name: '古材とは？',
            link: '#section1',
            dropdown: false,
          },
          {
            id: 2,
            name: '古材の魅力',
            link: '#section2',
            dropdown: false,
          },
          {
            id: 3,
            name: '取り扱い古材',
            link: '#section4',
            dropdown: false,
          },
          {
            id: 4,
            name: '大工の緒方について',
            link: '#section5',
            dropdown: false,
          },
        ]}
      />
      <PrivacySection companyName={companyName} />
      <Footer
        items={[
          { name: 'ニュース', link: '/' },
          { name: 'お問い合わせ', link: '/' },
          { name: '利用規約', link: '/' },
          { name: 'プライバシーポリシー', link: '/' },
        ]}
        copyrightText={companyName}
      />
    </div>
  );
};

export default PrivacyPage;

export const Head = () => (
  <Seo
    cover={'/main.png'}
    description="Webサイト制作のサンプルページです。ご気軽に閲覧してください。"
  />
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
